<template>
  <div class="upload">
    <p class="theme-color">资产证明</p>
    <div class="image-assets">
      <input
        type="file"
        accept="image/*,application/pdf"
        class="image"
        id="uploadImage1"
        style="visibility: hidden"
        @change="selectFileImage($event, '', 1)"
      />
      <label for="uploadImage1">
        <van-image class="showimage-assets" :src="pic1 || defaultPic"></van-image>
      </label>
    </div>
    <div v-if="pdf_name1" class="pdf-name">{{pdf_name1}}</div>
    <div class="image-assets">
      <input
        type="file"
        accept="image/*,application/pdf"
        class="image"
        id="uploadImage2"
        style="visibility: hidden"
        @change="selectFileImage($event, 1, 2)"
      />
      <label for="uploadImage2">
        <van-image class="showimage-assets" :src="pic2 || defaultPic"></van-image>
      </label>
    </div>
    <div v-if="pdf_name2" class="pdf-name">{{pdf_name2}}</div>
    <div class="image-assets">
      <input
        type="file"
        accept="image/*,application/pdf"
        class="image"
        id="uploadImage3"
        style="visibility: hidden"
        @change="selectFileImage($event, 2, 3)"
      />
      <label for="uploadImage3">
        <van-image class="showimage-assets" :src="pic3 || defaultPic"></van-image>
      </label>
    </div>
    <div v-if="pdf_name3" class="pdf-name">{{pdf_name3}}</div>
    <div class="upload-tip theme-color">点击图片上传或更新（jpg/jpeg/png/pdf）</div>
    <div class="common-assets-tip theme-color">
      <span>提示：</span><br />
      <span>1.可以是银行、券商的结单。需要显示客户姓名和800万港币以上等值的组合。</span><br />
      <span>2.时间需要限定于近12个月内。</span><br />
      <span>3.示例（点击可查看）：<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_5pi_01.jpg')">
          基金对账单
        </span>、<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_5pi_02.jpg')">
          理财结单
        </span>、<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_5pi_03.jpg')">
          银行存款证明
        </span>。</span><br />
    </div>
  </div>
</template>

<script>
import {
  Uploader, Image as VanImage, ImagePreview,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref, computed } from 'vue';
import uploadImage from './uploadImage';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';
import util from '../../utils/util';

const defaultPdf = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/7_pdf.png';
const defaultImage = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/3_live.png';
export default {
  name: 'Home',
  components: {
    [Uploader.name]: Uploader,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      defaultPic: defaultImage,
    };
  },
  methods: {
    showPreview(key) {
      ImagePreview([key]);
    },
    next() {
      this.$router.push({ path: '/info2' });
    },
    before() {
      this.$router.go(-1);
    },
    async selectFileImage(event, id, key) {
      const file = event.target.files[0];
      if (this.isAddAccount) await this.$api.submitLog({ type: `uploadAddAssetPhoto${(id || 0) + 1}` });
      if (file.type === 'application/pdf') {
        const form = new FormData();
        form.append('file', file, this.$util.setPdfName(file.name));
        const httprequest = this.isAddAccount
          ? httpAddApi.uploadPdf : httpApi.uploadPdf;
        const result = await httprequest(`asset_pdf${(id || 0) + 1}`, form);
        const { data = {} } = (result || { data: {} }).data;
        if (data.fileName.endsWith('.pdf')) {
          this[`pic${key}`] = defaultPdf;
          this[`pdf_name${key}`] = this.$util.formatPdfName(data.fileName);
        }
      } else {
        const httprequest = this.isAddAccount
          ? httpAddApi.updateImageResource : httpApi.postUploadImgesByType;
        uploadImage(file, `asset_photo${id}`, (photo) => {
          this[`pic${key}`] = photo;
          this[`pdf_name${key}`] = '';
        }, httprequest);
      }
    },
  },
  setup() {
    const fileList = ref([]);
    const pic1 = ref('');
    const pic2 = ref('');
    const pic3 = ref('');
    const pdf_name1 = ref('');
    const pdf_name2 = ref('');
    const pdf_name3 = ref('');
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit('setTitleValue', '上传资料');
      store.commit('setStepsShow', false);
      const httprequest = isAddAccount.value
        ? httpAddApi.getImageResource : httpApi.getUploadImgesByType;
      const result1 = await httprequest({ type: 'asset_photo' });
      const { value: result1_value } = (result1 || {}).data;
      if (result1_value) {
        if (result1_value.endsWith('.pdf')) {
          pdf_name1.value = util.formatPdfName(result1_value);
          pic1.value = defaultPdf;
        } else {
          pic1.value = result1_value;
        }
      }
      const result2 = await httprequest({ type: 'asset_photo1' });
      const { value: result2_value } = (result2 || {}).data;
      if (result2_value) {
        if (result2_value.endsWith('.pdf')) {
          pdf_name2.value = util.formatPdfName(result2_value);
          pic2.value = defaultPdf;
        } else {
          pic2.value = result2_value;
        }
      }
      const result3 = await httprequest({ type: 'asset_photo2' });
      const { value: result3_value } = (result3 || {}).data;
      if (result3_value) {
        if (result3_value.endsWith('.pdf')) {
          pdf_name3.value = util.formatPdfName(result3_value);
          pic3.value = defaultPdf;
        } else {
          pic3.value = result3_value;
        }
      }
    });
    return {
      isAddAccount,
      pic1,
      pic2,
      pic3,
      pdf_name1,
      pdf_name2,
      pdf_name3,
      fileList,
    };
  },
};
</script>

<style lang="less">
  .image-assets {
    width: 300px;
    height: 200px;
    margin: 10px auto;
    position: relative;
  }
  .showimage-assets {
    position: absolute;
    width: 300px;
    height: 200px;
    top: 0;
    left: 0;
  }
  .pdf-name {
    margin: auto;
    text-align: center !important;
    margin: 20px;
  }
  .upload-tip {
    text-align: center !important;
    color: @font-color-2;
    margin-top: @space-2;
  }
  .common-assets-tip {
    margin-top: 10px;
    color: @font-color-1;
    padding-left: 0px;
    padding-right: 0px;
  }
</style>
